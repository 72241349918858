import Api from "../../api/Api";
import { toast } from "react-toastify";
import axios from "axios";
import {
  ADD_IDS_FAIL,
  ADD_IDS_SUCCESS,
  DELETE_IDS_SUCCESS,
  GET_IDS,
  GET_IDS_SUCCESS,
  UPDATE_IDS_SUCCESS,
} from "./actionTypes";

export const loadApple = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.get("/apple/get");

  toast.promise(req, {
    pending: "Getting Apple IDs...",
    success: {
      render({ data }) {
        dispatch({
          payload: { apples: data.data.data, exports: data.data.exports },
          type: GET_IDS_SUCCESS,
        });
        return "Your Data Is Ready";
      },
    },
    error: {
      render({ data }) {
        return "An error occurred while getting Apple IDs";
      },
    },
  });
};

export const addApple = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/apple/add", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: ADD_IDS_SUCCESS,
        });
        return "Apple ID added successfully";
      },
    },
    error: {
      render({ data }) {
        // return data?.data.response.data?.message;

        return data?.response.data?.message;
      },
    },
  });
};

export const updateApple = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/apple/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: UPDATE_IDS_SUCCESS,
        });
        return "Apple ID updated successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while updating your Apple ID";
      },
    },
  });
};

export const deleteApple = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/apple/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: DELETE_IDS_SUCCESS,
        });
        return "Apple ID deleted successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while deleting your Apple ID";
      },
    },
  });
};

export const soldApple = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/apple/sold", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: UPDATE_IDS_SUCCESS,
        });
        return "Apple ID updated successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while updating your Apple ID";
      },
    },
  });
};

export const importApple = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/apple/import", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch(loadApple());
        return "Apple IDs imported successfully";
      },
    },
    error: {
      render({ data }) {
        return data?.response.data?.message;
      },
    },
  });
};

export const exportApple = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/apple/export", { number: data.formData.number });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadApple());

        window.open(response.data.data, "_blank");
        return "Apple IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const exportAppleQr = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/apple/export/qr", {
    number: data.number,
    image: data.image,
    shownote: data.shownote,
    note: data.note,
  });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadApple());

        window.open(response.data.data, "_blank");
        return "Apple IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};
